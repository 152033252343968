<template>
    <v-container class="my-5">
        <v-row>
            <v-card width="100%">
                <v-toolbar flat color="secondary" dark>
                    <v-toolbar-title>Most asked questions</v-toolbar-title>
                </v-toolbar>
                <v-tabs color="secondary" vertical>
                    <v-tab>
                        <v-icon>mdi-airplane</v-icon>
                        Flights
                    </v-tab>
                    <v-tab>
                        <v-icon>mdi-domain</v-icon>
                        Hotels
                    </v-tab>
                    <v-tab>
                        <v-icon>mdi-car</v-icon>
                        Cars
                    </v-tab>

                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <h3 class="primary--text">What Is a Booking Reference or PNR?</h3>
                                <p class="body-1">You can get your reference or confirmation number at the top of your profile and in your confirmation email.
                                    your airline confirmation number is a 6-digit alphanumeric code (ex: JK19LC).
                                </p>
                                <h3 class="primary--text">How can I get a copy of my itinerary or booking details?</h3>
                                <p class="body-1">Your itinerary and booking details are available to you at all times through your profile page and through your confirmation email. You can also print and share your itinerary by email through your profile page.</p>
                                <h3 class="primary--text">what should i do if i can't find my confirmation email?</h3>
                                <p class="body-1">First, check your email inbox, spam, and junk folders, if you still can't find your confirmation call Support at {{phone}} or sending an email to {{email}}.</p>
                                <h3 class="primary--text">Why should I book with {{site}}?</h3>
                                <p class="body-1">What sets {{site}} apart from everyone else is the process that occurs as soon as you search for flights. The technology that our team has developed allows us to instantaneously search for flights with over 200 airlines and work out with them to offer you the best flight options at the cheapest prices. We mainly do this faster and more efficiently than other websites</p>
                                <h3 class="primary--text">What are your payment options?</h3>
                                <p class="body-1">{{site}}  accepts payments by credit and debit cards. Every purchase must be completed in full using one payment method. </p>
                                <h3 class="primary--text">How many bags can I bring?</h3>
                                <p class="body-1">For the most updated information, please visit your airline's website .</p>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                        <v-card-text>
                            <h3 class="primary--text">How do I know if my hotel reservation is confirmed?</h3>
                            <p>Once you complete your reservation, a confirmation page will appear on your screen. You will also receive a confirmation email.Your confirmation includes all the details of your reservation, your booking number.</p>
                            <h3>When can I check-in and check-out of the hotel?</h3>
                            <p>Each hotel has a different check-in and check-out time.If the times offered by the hotel don’t line up with your flight itinerary, we recommend contacting them directly.</p>
                            <h3>what is the difference between a Double room and a Twin room?</h3>
                            <p>Double Room has one double bed , Twin Room has two single beds. </p>
                        </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                        <v-card-text>
                            <h3 class="primary--text">How can I rent a car on {{site}}?</h3>
                            <p> To rent a car for your trip:
                                <ol>
                                    <li> Go to the {{site}} car rental service.</li>
                                    <li> Enter your destination ,travel dates,pick-up and drop-off times . </li>
                                    <li>Select a vehicle for your needs and complete your reservation. </li>
                                </ol>
                            </p>
                        </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-row>
        <v-row justify="start" class="mt-5">
            <div>
                <h4>Call us on</h4>
                <a class="text-decoration-none" target="_blank" :href="`tel:${phone}`"><v-icon>mdi-phone</v-icon> {{phone}}</a>
            </div>
            <div class="ml-5">
                <h4>Email us on</h4>
                <a class="text-decoration-none" target="_blank" :href="`mailto:${email}`"><v-icon>mdi-email</v-icon> {{email}}</a>
            </div>
        </v-row>
    </v-container>
</template>

<script>
export default {
  title: '-Help',
  computed: {
    site () {
      return process.env.VUE_APP_DEFAULT_TITLE
    },
    phone () {
      return process.env.VUE_APP_DEFAULT_PHONE
    },
    email () {
      return process.env.VUE_APP_DEFAULT_EMAIL
    }
  }
}
</script>

<style>
.v-tab {
    background-color: #fff !important;
}
.v-tab.v-tab--active {
    background-color: #fff !important;
}
</style>
